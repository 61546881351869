import React, { useEffect, useState } from 'react';
import Resume from './Resume';
import Contact from './Contact';
import { twMerge } from 'tailwind-merge';
import { FiArrowRight } from 'react-icons/fi'
import { SiGithub, SiLinkedin, SiJira, SiMongodb, SiTailwindcss } from 'react-icons/si'
import { FaRegCopy, FaReact, FaRecycle, FaAws, FaRegCalendarCheck } from 'react-icons/fa6';
import { TbCircleArrowUpRight } from 'react-icons/tb';
import { IoMdPeople } from 'react-icons/io';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import nicoAvatar from '../assets/nico-avatar.png';
import gmailIcon from '../assets/gmail-icon.png'
import cv from '../assets/cv.png';
import javaIcon from '../assets/Java.png';
import vueIcon from '../assets/VueIcon.png'

export const Bento = () => {
    const [isResumeOpen, setIsResumeOpen] = useState(false);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const tabs = [
        "Home",
        "Works",
        "Socials",
        "Skills"
    ]
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const openResume = () => {
        setIsResumeOpen(true);
    };

    const closeResume = () => {
        setIsResumeOpen(false);
    };

    const openContact = () => {
        setIsContactOpen(true);
    }

    const closeContact = () => {
        setIsContactOpen(false);
    }

    return (
        <div className="min-h-screen bg-customWhite">
            <div className="w-full bg-white mb-8">
                <div className="mx-auto max-w-5xl grid grid-cols-4 gap-6
                text-customBlack text-center text-sm md:text-xl px-4">
                    {tabs.map((item) => (
                        <div
                            key={item}
                            onClick={() => setSelectedTab(item)}
                            className="p-3 cursor-pointer relative"
                        >
                            {item}
                            {item === selectedTab && (
                                <motion.div className="absolute bottom-0 left-0 w-full h-1 bg-customRed"
                                layoutId="underline" />
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <motion.div className="mx-auto px-4 max-w-5xl grid grid-flow-dense grid-cols-12 gap-4">
                <OrderBlock selectedTab={selectedTab} openResume={openResume} openContact={openContact} />
            </motion.div>
            <div className="mx-auto my-3 text-center text-sm text-customGray">
                Made with React, Tailwind, and Framer Motion
            </div>
            <AnimatePresence>
                {isContactOpen && (
                    <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }} 
                    className="fixed inset-0 bg-gray-800/60 backdrop-blur-sm z-10" 
                    onClick={closeContact}
                >
                    <Contact />
                </motion.div>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {isResumeOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }} 
                        className="fixed inset-0 bg-gray-800/60 backdrop-blur-sm z-10" 
                        onClick={closeResume}
                    >
                        <Resume />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

const Block = ({ className, ...rest }) => {
    return (
        <motion.div className={twMerge(
            "col-span-4 rounded-2xl bg-white p-6", className 
        )}
        initial={{ scale: 1 }}
        animate={{ scale: 1 }}
        whileHover={{
            scale: 1.05
        }}
        layout
        transition={{ 
            duration: 0.75,
            type: "spring",  
            ease: "anticipate" 
        }}
        {...rest}
        />
    ); 
}

const OrderBlock = ({ selectedTab, openResume, openContact }) => {
    const blocks = {
        Home: [
            <HeaderBlock key="header" openContact={openContact} />,
            <SocialsBlock key="socials" openResume={openResume} selectedTab={selectedTab} />,
            <ProjectsBlock key="projects" selectedTab={selectedTab} />,
            <SkillsBlock key="skills" selectedTab={selectedTab} />
        ],
        Works: [
            <ProjectsBlock key="projects" selectedTab={selectedTab} />,
            <SocialsBlock key="socials" openResume={openResume} selectedTab={selectedTab} />,
            <HeaderBlock key="header" openContact={openContact} />,
            <SkillsBlock key="skills" selectedTab={selectedTab} />,
        ],
        Socials: [
            <SocialsBlock key="socials" openResume={openResume} selectedTab={selectedTab} />,
            <HeaderBlock key="header" openContact={openContact} />,
            <SkillsBlock key="skills" selectedTab={selectedTab} />,
            <ProjectsBlock key="projects" selectedTab={selectedTab} />,
        ],
        Skills: [
            <SkillsBlock key="skills" selectedTab={selectedTab} />,
            <ProjectsBlock key="projects" selectedTab={selectedTab} />,
            <HeaderBlock key="header" openContact={openContact} />,
            <SocialsBlock key="socials" openResume={openResume} selectedTab={selectedTab} />,
        ]
    };
    
    return <>{blocks[selectedTab] || blocks.Home}</>;
};


const HeaderBlock = ({ openContact }) => {
    return (
        <Block className="col-span-12 row-span-4 md:col-span-6">
            <img
                src={nicoAvatar}
                alt="avatar"
                className="mb-4 size-14 rounded-xl"
            />
            <h1 className="mb-12 text-4xl font-medium leading-tight text-customBlack">
                Hi, I'm <span className="text-customRed">Nico</span>. {" "}
                    <span className="text-customGray">
                        I build cool websites like this one.
                    </span>
            </h1>
            <a href="#" className="flex items-center gap-1 text-customRed hover:underline" onClick={openContact}>  
                Contact me <FiArrowRight />
            </a>
        </Block>
    )
}

const SocialsBlock = ({ openResume, selectedTab }) => {
    const controls = useAnimation();  

    useEffect(() => {
        if (selectedTab === 'Socials') {
            controls.start({
                scale: [1, 1.05, 1.05, 1],
                transition: {
                    delay: 0.6, 
                    duration: 1,
                    times: [0, 0.3, 0.8, 1],
                    ease: "easeInOut" 
                },
            });
        }
    }, [selectedTab]);
    
    return (
    <>
        <Block
        animate={controls} 
        whileHover={{
            rotate: "2.5deg",
            scale: 1.1,
        }}
        whileTap={{
            scale: 1
        }}
        onClick={() => window.open("https://www.linkedin.com/in/nicolas-rodriguez-2a6642313", "_blank")}
        className="col-span-6 row-span-2 bg-blue-600 md:col-span-3">
            <a href="#" className="grid h-full place-content-center text-3xl text-white">
                <SiLinkedin />
            </a>
        </Block>
        <Block 
        animate={controls}
        whileHover={{
            rotate: "-2.5deg",
            scale: 1.1,
        }}
        whileTap={{
            scale: 1
        }}
        onClick={() => {
            if (navigator.clipboard && navigator.clipboard.writeText) { 
                navigator.clipboard.writeText("Nicorb02@gmail.com")
                    .then(() => console.log("Email address copied to clipboard."))
                    .catch(() => console.log("Failed to copy email address."));
            } else {
                // clipboard API not supported - fallback
                const textArea = document.createElement("textarea");
                textArea.value = "Nicorb02@gmail.com";
                document.body.appendChild(textArea);
                textArea.select();
                try {
                    document.execCommand("copy");
                } catch (err) {
                    console.log("Failed to copy email address.");
                }
                document.body.removeChild(textArea);
            }
        }}
        className="relative col-span-6 md:col-span-3 row-span-2 p-0 hover:cursor-pointer">
            <div href="#" className="absolute inset-0 flex items-center justify-center">
                <img src={gmailIcon} className="size-12" alt="gmail logo"/>
            </div>
            <FaRegCopy className="absolute bottom-4 right-4 text-customGray"/>
        </Block>
        <Block
        animate={controls} 
        onClick={openResume}
        whileHover={{
            rotate: "-2.5deg",
            scale: 1.1,
        }}
        whileTap={{
            scale: 1
        }}
        className="col-span-6 row-span-2 bg-white md:col-span-3 p-0">
            <a href="#" className="grid h-full place-content-center">
                <img src={cv} className="size-14" alt="resume icon"/>
            </a>
        </Block>
        <Block 
        animate={controls}
        whileHover={{
            rotate: "2.5deg",
            scale: 1.1,
        }}
        whileTap={{
            scale: 1
        }}
        onClick={() => window.open("https://github.com/Nicorb02", "_blank")}
        className="col-span-6 row-span-2 bg-green-500 md:col-span-3">
            <a href="#" className="grid h-full place-content-center text-3xl text-white">
                <SiGithub />
            </a>
        </Block>
    </>
)};

const SkillsBlock = ({ selectedTab }) => {
    const controls = useAnimation();  

    useEffect(() => {
        if (selectedTab === 'Skills') {
            controls.start({
                scale: [1, 1.08, 1.08, 1],
                transition: {
                    delay: 0.6, 
                    duration: 1, 
                    times: [0, 0.3, 0.8, 1],
                    ease: "easeInOut" 
                },
            });
        }
    }, [selectedTab]);
  
    return (
    <>
        <Block className={`col-span-2 row-span-2 ${selectedTab === "Works" ? "md:row-span-1": ""} flex flex-col justify-center items-center`} animate={controls} whileHover={null} >
            <FaReact className="size-8 md:size-14 text-sky-400" title="React"/>
        </Block>
        <Block className={`col-span-2 row-span-2 ${selectedTab === "Works" ? "md:row-span-1": ""} flex flex-col justify-center items-center bg-blue-600`} animate={controls} whileHover={null} >
            <SiJira className="size-8 text-white" title="Jira"/>
        </Block>
        <Block className="col-span-2 row-span-2 flex flex-col justify-center items-center p-0" animate={controls} whileHover={null} >
            <img src={javaIcon} title="Java" />
        </Block>
        <Block className="col-span-2 row-span-2 flex flex-col justify-center items-center bg-green-900" animate={controls} whileHover={null} >
            <SiMongodb className="size-12 text-white" title="MongoDB" />
        </Block>
        <Block className="col-span-2 row-span-2 flex flex-col justify-center items-center bg-cyan-500" animate={controls} whileHover={null} >
            <SiTailwindcss className="text-white size-10" title="TailwindCSS"/>
        </Block>
        <Block className={`col-span-2 row-span-2 md:row-span-2 flex flex-col justify-center items-center p-0`} animate={controls} whileHover={null} >
            <img src={vueIcon} className="size-6 md:size-10" title="Vue" />
        </Block>
        <Block className={`col-span-2 row-span-2 ${selectedTab === "Works" ? "md:row-span-1": ""} flex flex-col justify-center items-center bg-customRed`} animate={controls} whileHover={null} >
            <span className="text-white text-center text-xs md:text-base" title="Native Spanish Speaker">Hablo Español</span>
        </Block>
        <Block className={`col-span-2 row-span-2 ${selectedTab === "Works" ? "md:row-span-1": ""} flex flex-col justify-center items-center p-0 bg-[#232f3e]`} title="AWS" animate={controls} whileHover={null} >
            <FaAws className='size-8 text-white' />
        </Block>  
    </>
)};

const ProjectsBlock = ({ selectedTab }) => {
    const controls = useAnimation();  

    useEffect(() => {
        if (selectedTab === 'Works') {
            controls.start({
                scale: [1, 1.04, 1.04, 1],
                transition: {
                    delay: 0.6, 
                    duration: 1, 
                    times: [0, 0.3, 0.8, 1],
                    ease: "easeInOut" 
                },
            });
        }
    }, [selectedTab]);
    
    return (
    <>
        <Block 
        animate={controls}
        className={`col-span-12 ${selectedTab === "Works" ? 'row-span-3':'row-span-4'} md:col-span-4 bg-[url('../assets/Map.jpg')] bg-cover bg-center flex flex-col`}>
            <FaRecycle className="size-8 text-emerald-800" />
            <h1 className="text-2xl text-emerald-800 mb-3">
                Green Lifesaving Solutions
            </h1>
            <span className="text-emerald-700 font-light mb-2">
                A software suite to promote a green future by reducing recycling costs.
                Fleet management web app and mobile turn-by-turn navigation app with AI-generated routes.
            </span>
            <div className="relative text-customBlack ml-auto mt-auto">
                <a 
                    className="flex items-center gap-x-1"
                    href="https://www.linkedin.com/in/nicolas-rodriguez-2a6642313/details/projects/"
                    target="_blank"
                    rel="noopener noreferrer" 
                    title="LinkedIn Project">
                    <span>LinkedIn Project</span>
                    <TbCircleArrowUpRight className="size-6"/>
                </a>
                <span className='absolute text-customGray text-sm right-1'>Private Repo</span>
            </div>
        </Block>
        <Block 
        animate={controls}
        className="col-span-12 md:col-span-4 md:col-start-9 row-span-4 md:row-span-3 bg-[url('../assets/Orange.jpg')] bg-cover bg-center flex flex-col">
            <h1 className="flex text-2xl text-white mb-3">
                <FaRegCalendarCheck className='size-7 text-white mr-2'/>
                Sunny Side Up
            </h1>
            <span className="text-white font-light mb-2">
                A planner app with a simplisitic layout for users to organize daily tasks, notes, and events with
                daily, weekly, and monthly views.
                Built using React and Express.
            </span>
            <div className="text-white ml-auto mt-auto">
                <a 
                    className="flex items-center gap-x-1"
                    href="https://github.com/Nicorb02/Sunny-Side-Up"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="GitHub Public Repository">
                    <span>GitHub</span>
                    <TbCircleArrowUpRight className="size-6"/>
                </a>
            </div>
        </Block>
        <Block 
        animate={controls}
        className={`col-span-12 md:col-span-4 row-span-2 ${selectedTab === "Home" ? "md:row-start-5" : ""} bg-[url('../assets/bluegreenblur.jpg')] bg-cover bg-center flex flex-col`}>
            <h1 className="flex text-2xl text-white mb-3">
                <IoMdPeople className='size-7 text-white mr-2 mt-1'/>
                My Eggs
            </h1>
            <span className="text-white font-light mb-2">
                A place where users can manage their contacts. Built with HTML/CSS/JS.
            </span>
            <div className="text-white ml-auto mt-auto">
                <a 
                    className="flex items-center gap-x-1"
                    href="https://github.com/Nicorb02/MyEggs-Contact-Manager"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="GitHub Public Repository">
                    <span>GitHub</span>
                    <TbCircleArrowUpRight className="size-6"/>
                </a>
            </div>
        </Block>
        <Block 
        animate={controls}
        className={`col-span-8 md:col-span-4 ${selectedTab === "Home" ? 'md:row-end-9 md:col-end-13': ''} row-span-2 md:row-span-1 flex flex-col`}>
            <h1 className="flex md:text-2xl text-lg text-customBlack md:mb-3">
                This<span className='text-customRed ml-1'>Portfolio</span>
            </h1>
            <div className="text-customGray ml-auto mt-auto">
                <a 
                    className="flex items-center gap-x-1"
                    href="https://github.com/Nicorb02/portfolio"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="GitHub Public Repository">
                    <span>GitHub</span>
                    <TbCircleArrowUpRight className="size-6"/>
                </a>
            </div>
        </Block>
    </>
)};
