import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const greetings = [
    'Hola',      // Spanish
    'Halló',     // Icelandic
    'こんにちは', // Japanese
    'Bonjour',   // French
    '你好',      // Chinese
    'Merhaba',   // Turkish
    'שלום',      // Hebrew
    'Cześć',     // Polish
    'Salut',     // Romanian
    'Hallo',     // German
    '안녕하세요',  // Korean
    'Ciao',      // Italian
    'Olá',       // Portuguese
    'नमस्ते',     // Hindi
    'Hello',     // English
];

const Hello = () => {
    const [currentGreeting, setCurrentGreeting] = useState(0);
    const [showName, setShowName] = useState(false);
    const [allAnimationsComplete, setAllAnimationsComplete] = useState(false);
    const [renderComponent, setRenderComponent] = useState(true);

    useEffect(() => {
        if (currentGreeting < greetings.length - 1) {
            const timer = setTimeout(() => {
                setCurrentGreeting(currentGreeting + 1);
            }, 200);

            return () => clearTimeout(timer);
        } else {
            const delayTimer = setTimeout(() => {
                setShowName(true);
            }, 500);

            return () => clearTimeout(delayTimer);
        }
    }, [currentGreeting]);

    useEffect(() => {
        if (showName) {
            const scrollTimer = setTimeout(() => {
                setAllAnimationsComplete(true);
            }, 1500);

            return () => clearTimeout(scrollTimer);
        }
    }, [showName]);

    useEffect(() => {
    if (allAnimationsComplete) {
        window.scrollTo({
            top: window.innerHeight, // Scroll to the end of the first screen height
            behavior: 'smooth',
        });

        unrenderComponent();
    }
}, [allAnimationsComplete]);

    const unrenderComponent = () => {
        const scrollDelay = setTimeout(() => {
            setRenderComponent(false);
        }, 1000)

        return () => clearTimeout(scrollDelay);
    }

    if (!renderComponent) {
        return (<></>)
    }
    else {
        return (
            <motion.div layout className="flex items-center justify-center h-screen w-full bg-white">
                {/* Greeting animation */}
                <motion.h1
                    key={currentGreeting}
                    layout
                    transition={{ ease: "easeIn", duration: 0.25 }}
                    className={`text-5xl md:text-7xl text-center ${showName ? 'mr-3' : ''}`}
                >
                    {greetings[currentGreeting]}{showName && <span>,</span>}
                </motion.h1>

                {/* Name animation */}
                {showName && (
                    <motion.div
                        layout
                        initial={{ opacity: 0, x: '-50%' }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{
                            opacity: { delay: 0.6, duration: 0.4 },
                            x: { delay: 0.6, ease: "easeIn", duration: 0.4 },
                        }}
                        className="text-5xl md:text-7xl flex items-center justify-center text-center"
                    >
                        I'm <motion.span className="text-customRed ml-2">Nico</motion.span>
                    </motion.div>
                )}
            </motion.div>
        );
    }
};

export default Hello;
