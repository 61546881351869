import React from "react";
import { motion } from "framer-motion";

const Contact = () => {
    return (
        <motion.div 
            initial={{ opacity: 0, scale: 0.7 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.7 }}
            className="absolute z-20 inset-0 m-auto rounded-2xl bg-white p-4 max-w-md max-h-fit flex flex-col items-center text-customBlack"
        >
            <h1 className="text-2xl mb-3">
                Lets <span className="text-customRed">Connect</span>!
            </h1>
            <div className="flex flex-row">
                <div className="flex flex-col">
                    <span>E-mail:</span>
                    <span>Phone Number:</span>
                </div>
                <div className="flex flex-col text-right">
                    <span>Nicorb02@gmail.com</span>
                    <span>+1(407)810-1064</span>
                </div>
            </div>
            <span className="text-customGray mt-3">Located in Orlando, FL</span>    
        </motion.div>
    );
};

export default Contact;
