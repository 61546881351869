import React, { useState, useEffect } from 'react';
import Hello from './components/Hello';
import { Bento } from './components/Bento';

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize(); 
  }, []);

  return (
    <div>
      {!isMobile && <Hello />}
      <Bento />
    </div>
  );
}

export default App;
