import React from "react";
import { motion } from "framer-motion";
import { FiDownload } from 'react-icons/fi';
import resumejpg from '../assets/Resume - Nicolas Rodriguez.jpg';

const Resume = () => {
    const dontClose = (e) => {
        e.stopPropagation();
    };

    return (
        <motion.div 
            initial={{ opacity: 0, scale: 0.7 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.7 }}
            className="absolute z-20 inset-0 m-auto rounded-2xl bg-white p-4 md:max-w-xl max-h-fit"
        >
            <img src={resumejpg} />
            <a
                href="/Resume - Nicolas Rodriguez.pdf"
                download="Resume_Nicolas_Rodriguez"
            >
                <FiDownload onClick={dontClose} 
                className="absolute bottom-4 right-4 text-customGray size-8 cursor-pointer hover:text-customBlack" />
            </a>
        </motion.div>
    );
};

export default Resume;
